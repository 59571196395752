import React, { Component } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import avatar from "../avatar.webp";
import { useState, useEffect } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import Swal from "sweetalert2";
import { Height } from "@mui/icons-material";
import { color, fontSize } from "@mui/system";

const shop = [
  {
    id: 1,
    shopSize: "FIVE STAR Glass House",
  },
  {
    id: 2,
    shopSize: "FIVE STAR Shop M",
  },
  {
    id: 3,
    shopSize: "FIVE STAR Shop L",
  },
];

class profile_CPage extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef(null);
    this.state = {
      fransID: "",
      fransName: "",
      name: "",
      Lname: "",
      phone: "",
      checkPhone: false,
      phone2: "",
      mail: "",
      checkEmail: false,
      nloca: "",
      zipcode: "",
      nsubpro: "",
      ndistrict: "",
      nprovince: "กรุงเทพมหานคร",
      JWT: "",
      allprovinces: [],
      allapmer: [],
      alldistrict: [],
      subprovince: "",
      franchiseSize: "",
      file: null,
      lat: "",
      lng: "",
      isEdit: false,
      dropdownOpen: "",
      dropdownOpen: false,
      test: "",
    };
  }

  componentWillMount() {
    this.getprovinces();
    this.getamperby_provinces(10);
    this.getdistrict(1001);
    this.getoneprofile();
  }

  getprovinces = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}allprovinces`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
      });
      var data = await response.json();
      this.setState({ allprovinces: data.result });
    } catch (err) {
      return err;
    }
  };

  getamperby_provinces = async (value) => {
    let body = {
      province_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}districts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      this.setState({ allapmer: data.result });
    } catch (err) {
      return err;
    }
  };

  otpres = async () => {
    let { phone } = this.state;
    let body = {
      phone: phone,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otprequest-check`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      localStorage.setItem("token", result.token);
      if (result.status_code == 200) {
        Swal.fire({
          title: "เบอร์นี้ถูกใช้งานไปแล้ว",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if ((result.status = "success")) {
        localStorage.setItem("phone_reset", phone);
        window.location = "/verify";
      }
    } catch (err) {
      return err;
    }
  };

  getdistrict = async (value) => {
    let body = {
      district_code: value,
    };
    console.log(value);
    try {
      var response = await fetch(`${ENDPOINT_URL}subdistricts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      this.setState({ alldistrict: data.result });
      console.log(data);
    } catch (err) {
      return err;
    }
  };

  handleChange = (event) => {
    let { phone } = this.state;
    var phones = event.target.value;
    this.setState({ phone: phones });
    if (phone.length === 10 && /[0-9]/.test(phone)) {
      return this.setState({ checkPhone: false });
    } else {
      return this.setState({ checkPhone: true });
    }
  };

  validate = (values) => {
    var errors = {};

    if (!values.email) {
      this.setState({ checkEmail: false });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
      this.setState({ checkEmail: true });
    } else {
      this.setState({ checkEmail: false });
    }
    return errors;
  };

  profileCPost = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let {
      fransID,
      franchiseSize,
      fransName,
      name,
      Lname,
      phone,
      phone2,
      mail,
      nloca,
      ndistrict,
      nsubpro,
      nprovince,
      zipcode,
      checkPhone,
      checkEmail,
      lat,
      lng,
    } = this.state;
    let body = {
      token: jwt,
      data: {
        franchise_id: fransID,
        franchise_size: franchiseSize,
        franchise_name: "5stars",
        franchise_branchname: fransName,
        franchise_name: name,
        // lname: Lname,
        tel: phone,
        // tel2: phone2,
        email: mail,

        // franchise_gps: "",
      },
    };
    console.log(nprovince);
    console.log(body);
    try {
      // var response = await fetch(`${ENDPOINT_URL}franprofile`, {
      //   method: "POST",
      //   headers: {
      //     Accept: "appclication/json",
      //     "Content-Type": "appclication/json",
      //     Authorization: "Bearer " + jwt,
      //   },
      //   body: JSON.stringify(body),
      // });
      // var data = await response.json();
      // console.log(data);

      if (
        name === "" ||
        Lname === "" ||
        phone === "" ||
        nloca === "" ||
        nsubpro === "" ||
        ndistrict === "" ||
        zipcode === "" ||
        fransID === "" ||
        fransName === "" ||
        franchiseSize === ""
      ) {
        Swal.fire({
          title: "Error!",
          text: "กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (0 == 1 /**check picture**/) {
        Swal.fire({
          title: "Error!",
          text: "กรุณาใส่รูปโปรไฟล์",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (checkPhone === true) {
        Swal.fire({
          title: "Error!",
          text: "เบอร์โทรศัพท์ไม่ถูกต้อง",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } /* if (data.code === 200) */ else {
        Swal.fire({
          title: "Success!",
          text: "บันทึกข้อมูลสำเร็จ" /*  และเผยแพร่ใบสมัครสำเร็จ */,
          icon: "success",
          confirmButtonText: "ตกลง",
        });
        var response = await fetch(`${ENDPOINT_URL}franprofile`, {
          method: "POST",
          headers: {
            Accept: "appclication/json",
            "Content-Type": "appclication/json",
            Authorization: "Bearer " + jwt,
          },
          body: JSON.stringify(body),
        });
        var data = await response.json();
        console.log(data);
      }
    } catch (err) {
      return err;
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  handleFileInputChange = (e) => {
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });
  };

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };

  onChange = (e) => {
    console.log("file uploaded: ", e.target.files[0]);
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  _handleReaderLoaded = (e) => {
    console.log("file uploaded 2: ", e);
    let binaryString = e.target.result;
    this.setState({
      base64URL: btoa(binaryString),
    });
  };

  getoneprofile = async () => {
    const dataw = localStorage.getItem("userdata");
    var username = localStorage.getItem("username");
    let dataww = JSON.parse(dataw);
    try {
      var response = await fetch(`${ENDPOINT_URL}getonefran`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + dataww.token,
        },
        body: JSON.stringify({ franchise_id: dataww.worker_id }),
      });
      var data = await response.json();
      let user = data.result[0];
      this.setState({
        fransID: user.franchise_id,
        fransName: user.franchise_branchname,
        name: user.franchise_name,
        Lname: user.lname,
        phone: user.tel,
        phone2: user.tel2,
        mail: user.email,
        nloca: user.location,
        zipcode: user.franchise_zip,
        nsubpro: user.franchise_district,
        ndistrict: user.franchise_subdistrict,
        nprovince: user.franchise_province,
        franchiseSize: user.franchise_size,
        lat: user.latitude,
        lng: user.longtitude,
      });
    } catch (err) {
      return err;
    }
  };

  toggledrop = () => this.setState({ dropdownOpen: (prevState) => !prevState });

  toggle = this.setState((prevState) => ({
    dropdownOpen: !prevState.dropdownOpen,
  }));

  postaddress = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let { ndistrict, nsubpro, nprovince, nloca, zipcode, lat, lng } =
      this.state;
    let body = {
      token: jwt,
      data: {
        location: nloca,
        franchise_subdistrict: ndistrict[1],
        franchise_district: nsubpro[1],
        franchise_province: nprovince[1] || "กรุงเทพมหานคร",
        franchise_zip: zipcode,
        latitude: lat,
        longtitude: lng,
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}franprofile`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
    } catch (err) {
      return err;
    }
  };

  render() {
    let {
      phone,
      phone2,
      handleChange,
      allprovinces,
      allapmer,
      nsubpro,
      alldistrict,
      zipcode,
      base64URL,
      ndistrict,
      nprovince,
      test,
      name,
      Lname,
      mail,
      fransID,
      fransName,
      nloca,
      franchiseSize,
      lat,
      lng,
      isEdit,
    } = this.state;
    return (
      <div className="container" style={{ marginTop: "90px" }}>
        <Container>
          <Row className="m-auto align-self-center">
            <Col>
              <Card
                body
                className="my-2"
                style={{
                  width: "100%",
                }}
              >
                <CardTitle tag="h5">รายละเอียดโปรไฟล์</CardTitle>
                <CardTitle
                  style={{
                    borderBottom: "2px solid",
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "-17px",
                    marginRight: "-17px",
                    borderBottomColor: "#EBE9F1",
                  }}
                />
                <Row style={{ marginBottom: "15px" }}>
                  {/* <Col
                    lg={1}
                    className="d-lg-block d-none"
                    style={{ marginRight: "40px" }}
                  >
                    {base64URL != null && (
                      <img
                        src={`data:image;base64,${base64URL}`}
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                  </Col> */}
                  {/* <Col
                    md={3}
                    className="d-md-block d-lg-none d-none"
                    style={{ marginRight: "40px" }}
                  >
                    {base64URL != null && (
                      <img
                        src={`data:image;base64,${base64URL}`}
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                  </Col> */}
                  {/* <Row className="d-xs-block d-lg-none d-md-none">
                    <Col xs={6} className="d-xs-block d-lg-none d-md-none">
                      {base64URL != null && (
                        <img
                          src={`data:image;base64,${base64URL}`}
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "6px",
                          }}
                        />
                      )}
                     
                    </Col>
                    <Col xs={6} className="d-xs-block d-lg-none d-md-none">
                      <Button
                        style={{
                          background: "#49C5B1",
                          marginTop: "15px",
                          border: "0px",
                        }}
                        block
                        onClick={this.handleClick}
                      >
                        อัพโหลดรูป
                      </Button>
                      <input
                        type="file"
                        ref={this.hiddenFileInput}
                        onChange={(e) => {
                          this.onChange(e);
                          this.handleFileInputChange();
                        }}
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }}
                      />
                      <Button
                        style={{
                          background: "#ED1B24",
                          marginTop: "15px",
                          border: "0px",
                        }}
                        block
                        type="submit"
                        value="Submit"
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row> */}
                  {/* <Col lg={10} md={8}>
                    <Row style={{ marginBottom: "15px" }}>
                      <Col xl={2} lg={3} className="d-lg-block d-none">
                        <Button
                          style={{
                            background: "#49C5B1",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          block
                          onClick={this.handleClick}
                        >
                          อัพโหลดรูป
                        </Button>
                        <input
                          type="file"
                          ref={this.hiddenFileInput}
                          onChange={(e) => {
                            this.onChange(e);
                            this.handleFileInputChange();
                          }}
                          accept=".jpg, .jpeg, .png"
                          style={{ display: "none" }}
                        />
                      </Col>
                      <Col lg={2} className="d-lg-block d-none">
                        <Button
                          style={{
                            background: "#ED1B24",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          type="submit"
                          value="Submit"
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col md={4} className="d-md-block d-lg-none d-none">
                        <Button
                          style={{
                            background: "#49C5B1",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          block
                          onClick={this.handleClick}
                        >
                          อัพโหลดรูป
                        </Button>
                        <input
                          type="file"
                          ref={this.hiddenFileInput}
                          onChange={(e) => {
                            this.onChange(e);
                            this.handleFileInputChange();
                          }}
                          accept=".jpg, .jpeg, .png"
                          style={{ display: "none" }}
                        />
                      </Col>
                      <Col md={2} className="d-md-block d-lg-none d-none">
                        <Button
                          style={{
                            background: "#ED1B24",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          type="submit"
                          value="Submit"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p style={{ color: "#6E6B7B" }}>
                          Allowed file type: png, jpg, jpeg.
                        </p>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        ชื่อแฟรนไชน์<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Input
                        name="name"
                        placeholder="ชื่อ"
                        type="name"
                        value={name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        หมายเลขแฟรนไชส์<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Input
                        name="franchise"
                        placeholder="ID"
                        type="text"
                        disabled
                        value={fransID}
                        onChange={(e) => {
                          this.setState({ fransID: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        ชื่อสาขา<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Input
                        name="branch"
                        type="text"
                        placeholder="ชื่อสาขา"
                        value={fransName}
                        onChange={(e) => {
                          this.setState({ fransName: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        ขนาดร้าน<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggledrop}
                      >
                        <DropdownToggle caret outline style={{ width: "100%" }}>
                          {this.state.franchiseSize === ""
                            ? "ขนาดร้าน"
                            : this.state.franchiseSize}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "100%" }}>
                          {shop.map((shopS) => (
                            <DropdownItem
                              key={shopS.id}
                              onClick={(e) => {
                                this.setState({
                                  franchiseSize: (this.state.franchiseSize =
                                    shopS.shopSize),
                                });
                              }}
                            >
                              {shopS.shopSize}
                            </DropdownItem>
                          ))}
                          <DropdownItem
                            onClick={(e) => {
                              this.setState({
                                franchiseSize: (this.state.franchiseSize =
                                  "อื่นๆ"),
                              });
                            }}
                          >
                            อื่นๆ
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        เบอร์โทรศัพท์<label style={{ color: "red" }}>*</label>
                      </Label>
                      <br />
                      <input
                        style={{
                          borderRadius: "6px",
                          width: "86%",
                          height: "38px",
                          marginRight: "20px",
                          border: "1px solid lightgrey",
                        }}
                        placeholder="(+66) xx-xxx-xxxx"
                        value={phone}
                        // disabled
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                          this.handleChange(e.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          this.otpres();
                        }}
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </Col>
                  {/* <Col md={3}>
                    <FormGroup>
                      <Label>เบอร์โทรศัพท์ 2</Label>
                      <Input
                        placeholder="(+66) xx-xxx-xxxx"
                        value={phone2}
                        onChange={(e) => {
                          this.setState({ phone2: e.target.value });
                          this.handleChange(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        ที่ตั้งร้าน<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Input
                        name="address"
                        placeholder="ที่ตั้งร้าน"
                        value={nloca}
                        onChange={(e) => {
                          this.setState({ nloca: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    {isEdit === false ? (
                      <div>
                        <Label>
                          จังหวัด<label style={{ color: "red" }}>*</label>
                        </Label>{" "}
                        <Button
                          style={{
                            height: "20px",
                            fontSize: "10px",
                          }}
                          onClick={() => this.setState({ isEdit: !isEdit })}
                        >
                          edit
                        </Button>{" "}
                        <br />
                        <Input value={nprovince} disabled></Input>
                      </div>
                    ) : (
                      <FormGroup>
                        <Label>
                          จังหวัด<label style={{ color: "red" }}>*</label>
                        </Label>
                        <Button
                          style={{
                            height: "20px",
                            fontSize: "10px",
                          }}
                          onClick={() => this.setState({ isEdit: !isEdit })}
                        >
                          edit
                        </Button>{" "}
                        <Input
                          name="select"
                          type="select"
                          placeholder="จังหวัด"
                          value={nprovince}
                          list={allprovinces}
                          onChange={(e, id) => {
                            this.setState({
                              nprovince: e.target.value.split(","),
                            });
                            this.getamperby_provinces(e.target.value);
                          }}
                        >
                          <option value="0" selected>
                            จังหวัด
                          </option>
                          {allprovinces.map((item) => (
                            <option
                              id={item.code}
                              key={item.code}
                              value={[item.code, item.name_th]}
                              text={item.name_th}
                              //children={item.name_th}
                            >
                              {item.name_th}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {isEdit === false ? (
                      <div>
                        <Label>
                          อำเภอ<label style={{ color: "red" }}>*</label>
                        </Label>
                        <br />
                        <Input value={nsubpro} disabled></Input>
                      </div>
                    ) : (
                      <FormGroup>
                        <Label>
                          อำเภอ<label style={{ color: "red" }}>*</label>
                        </Label>
                        <Input
                          name="city"
                          type="select"
                          placeholder="อำเภอ"
                          value={nsubpro}
                          invalid={nsubpro != "" ? false : true}
                          onChange={(e) => {
                            this.setState({
                              nsubpro: e.target.value.split(","),
                            });
                            this.getdistrict(e.target.value);
                          }}
                        >
                          <option value="0" selected>
                            อำเภอ
                          </option>
                          {allapmer.map((item) => (
                            <option
                              key={item.code}
                              value={[item.code, item.name_th]}
                              text={item.name_th}
                            >
                              {item.name_th}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md={6}>
                    {isEdit === false ? (
                      <div>
                        <Label>
                          ตำบล<label style={{ color: "red" }}>*</label>
                        </Label>
                        <br />
                        <Input value={ndistrict} disabled></Input>
                      </div>
                    ) : (
                      <FormGroup>
                        <Label>
                          ตำบล<label style={{ color: "red" }}>*</label>
                        </Label>
                        <Input
                          name="city2"
                          type="select"
                          placeholder="ตำบล"
                          value={ndistrict}
                          onChange={(e) => {
                            this.setState({
                              ndistrict: e.target.value.split(","),
                            });
                          }}
                        >
                          <option value="0" selected>
                            ตำบล
                          </option>
                          {alldistrict.map((item) => (
                            <option
                              key={item.code}
                              value={[item.code, item.name_th]}
                              text={item.name_th}
                            >
                              {item.name_th}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        รหัสไปรษณีย์<label style={{ color: "red" }}>*</label>
                      </Label>
                      <Input
                        name="zipcode"
                        placeholder="รหัสไปรษณีย์"
                        invalid={zipcode != "" ? false : true}
                        value={zipcode}
                        onChange={(e) => {
                          this.setState({ zipcode: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>ละติจูด</Label>
                          <Input
                            name="lat"
                            placeholder="ละติจูด"
                            value={lat}
                            onChange={(e) => {
                              this.setState({ lat: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>ลองจิจูด</Label>
                          <Input
                            name="lng"
                            placeholder="ลองจิจูด"
                            value={lng}
                            onChange={(e) => {
                              this.setState({ lng: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <p style={{ color: "red", fontSize: "9px" }}>
                  *กรุณากดบันทึกที่อยู่ก่อน
                </p>
                <Col md={6}>
                  <Button
                    style={{ background: "#49C5B1" }}
                    onClick={(e) => {
                      this.postaddress();
                    }}
                  >
                    บันทึกที่อยู่
                  </Button>
                </Col>
                <Row>
                  <Col xl={10} lg={8} className="d-lg-block d-md-none"></Col>
                  <Col xl={1} lg={2} className="d-lg-block d-md-none">
                    <Button
                      style={{
                        background: "#49C5B1",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      block
                      onClick={() => {
                        this.profileCPost();
                      }}
                      // type="submit"
                      // value="Submit"
                    >
                      บันทึก
                    </Button>
                  </Col>
                  <Col xl={1} lg={2} className="d-lg-block d-md-none">
                    <Button
                      style={{
                        background: "#ED1B24",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      block
                      // type="submit"
                      // value="Submit"
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                  <Col md={8} className="d-md-block d-lg-none d-none"></Col>
                  <Col md={2} className="d-md-block d-lg-none d-none">
                    <Button
                      style={{
                        background: "#49C5B1",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      block
                      onClick={() => {
                        this.profileCPost();
                      }}
                      // type="submit"
                      // value="Submit"
                    >
                      บันทึก
                    </Button>
                  </Col>
                  <Col md={2} className="d-md-block d-lg-none d-none">
                    <Button
                      style={{
                        background: "#ED1B24",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      block
                      // type="submit"
                      // value="Submit"
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default profile_CPage;
