import React from "react";
import {
  Card,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
//import { Modal } from 'antd';
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { useState } from "react";
import { ENDPOINT_URL, ENDPOINT_OTP } from "../../../config/app-config";
import Swal from "sweetalert2";

const LoginPage = () => {
  const [inputs, setInputs] = useState({});
  const [check, setCheck] = useState(false);
  const [password, setPassword] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePass = (event) => {
    const password = event.target.value;
    setPassword(password);
    if (password.length >= 8 && password.length <= 12) {
      return setCheck(false);
    } else {
      return setCheck(true);
    }
  };

  const test = async () => {
    let body = {
      username: inputs.username,
      password: password,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}login`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 404) {
        Swal.fire({
          title: "ไม่มี Username ที่ท่านกรอก",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (data.code === 500) {
        Swal.fire({
          title: "กรุณากรอกUsername และ Password",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (data.code === 403) {
        Swal.fire({
          title: "รหัสผ่านผิด",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (data.message === "user not found") {
        Swal.fire({
          title: "ไม่มี Username ที่ท่านกรอก",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if (data.code === 200) {
        let userData = JSON.stringify(data.result);
        localStorage.setItem("jwt_token", data.result.token);
        localStorage.setItem("userdata", userData);
        localStorage.setItem("username", data.result.username);
        if (data.result.login_firsttime === true) {
          window.location = "/chagepassword";
        } else if (data.result.user_type === 2) {
          window.location = "/home_C";
        } else if (data.result.user_type === 1) {
          window.location = "/home";
        }
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      <LoginStyle>
        <div className="container">
          <Form inline onSubmit={"/Home"}>
            <Container className="d-flex vh-100">
              <Row className="m-auto align-self-center">
                <Col>
                  <Card body className="my-2">
                    <CardTitle align="center" tag="h2">
                      WorkWith
                    </CardTitle>
                    <FormGroup>
                      <Label>เบอร์โทรศัพท์</Label>
                      <Label for="exampleTelnum" hidden>
                        Telnum
                      </Label>
                      <Input
                        name="username"
                        placeholder="Telphone number"
                        type="text"
                        value={inputs.username || ""}
                        onChange={handleChange}
                      />
                      <Label style={{ fontSize: "12px", color: "#6E6B7B" }}>
                        *แฟรนไชส์ที่ต้องการเข้าสู่ระบบ กรุณากรอกเลข CV*
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col lg="7" md="7" xs="7">
                          <Label>รหัสผ่าน</Label>
                        </Col>
                        <Col lg="5" md="5" xs="5">
                          <a
                            className="text-decoration-none"
                            style={{ color: "#49C5B1" }}
                            href="/forget"
                          >
                            ลืมรหัสผ่าน?
                          </a>
                        </Col>
                      </Row>
                      <Label for="examplePassword" hidden>
                        Password
                      </Label>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        invalid={check}
                        value={password || ""}
                        onChange={handlePass}
                      />
                      {password.length > 12 ? (
                        <span style={{ color: "red" }}>รหัสผ่านเกิน</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    {/*  <FormGroup check>
                      <Input type="checkbox" />
                      <Label check>จำชื่อผู้ใช้</Label>
                    </FormGroup> */}
                    <Button
                      style={{
                        background: "#49C5B1",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      //tag="input"
                      // type="submit"
                      onClick={() => {
                        test();
                      }}
                    >
                      เข้าสู่ระบบ
                    </Button>
                    <FormGroup align="center" style={{ marginTop: "25px" }}>
                      <Label>หากยังไม่มีบัญชี</Label>
                      <a
                        href="/register"
                        className="text-decoration-none"
                        style={{ marginLeft: "5px", color: "#49C5B1" }}
                      >
                        สร้างบัญชีใหม่
                      </a>
                    </FormGroup>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </LoginStyle>
    </div>
  );
};

const LoginStyle = styled.div``;

export default LoginPage;
